.welcomePage {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 250);
}

.welcomeCard {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 1px 1px 5px lightgray;
  padding: 36px 48px;
  background-color: white;
}

.branding {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  line-height: 32px;
}

.logo {
  width: 256px;
  height: 256px;
  border-radius: 32px;
  box-shadow: 2px 2px 5px gray;
}

.title {
  font-size: 22px;
  font-weight: 400;
}
