.cardTitle {
  font-family: Arial;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.idContainer {
  text-align: left;
}

.subTitle {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.notes {
  padding: 0.5em 1em;
  border-radius: 1em;
  background-color: #d9ebff;
  color: #0069ff;
  width: 75%;
  font-family: Arial;
  text-align: center;
  font-weight: normal;
  font-size: small;
}

.qrTitle {
  padding: 0px;
  font-family: Arial;
  text-align: left;
  font-weight: bold;
  font-size: large;
}

.qrNotes {
  padding: 0px;
  font-family: Arial;
  text-align: left;
  font-weight: normal;
  font-size: small;
}

.select {
  background-color: rgba(244, 244, 246, 1) !important;
  color: black !important;
  min-width: 300px;
  text-align: left;
  border-radius: 8px;
}

.spacer {
  flex-grow: 1;
}

.secondaryButton {
  border-color: #d9ebff !important;
  background-color: #d9ebff !important;
  color: #0069ff !important;
}

.clickable {
  cursor: pointer;
}

.modalTitle {
  padding: 0.75em 1.25em;
  background-color: #d9ebff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.ant-modal-content {
  padding: 0 !important;
  overflow: hidden !important;
  max-width: 700px;
  margin: auto;
}

.previewContainer {
  border-radius: 1em;
  background-color: #efefef;
  height: 360px;
  text-align: center;
  overflow: hidden;
}

.controlContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.content-container {
  height: 360px;
}

.livelinessContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 480px;
  height: 280px;
  border-radius: 24px;
  background-color: rgba(56, 56, 88, 1);
}

.livelinessOverlay {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  width: 240px;
  height: 240px;
}

.lo25 {
  border-left: 5px dashed rgba(100, 255, 100, 1);
}

.lo50 {
  border-bottom: 5px dashed rgba(100, 255, 100, 1);
}

.lo75 {
  border-right: 5px dashed rgba(100, 255, 100, 1);
}

.lo100 {
  border-top: 5px dashed rgba(100, 255, 100, 1);
}

#livelinessVideo {
  width: 360px;
  height: 280px;
  z-index: 2;
  clip-path: circle(35% at 50% 50%);
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-container {
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db; /* Set the color of the spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* You can use a loading spinner or a waiting GIF for the overlay */
.loading-overlay::after {
  content: "Processing...";
  font-size: 18px;
  color: #333;
}

.actual-content {
  /* Style your actual content */
  padding: 0px;
  border: 0px solid #ccc;
  margin: 0px;
  box-sizing: border-box;
}
