.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background-color: white;
  overflow: hidden;
  height: 440px;
}

.sectionHeader {
  background-color: rgba(232, 232, 235, 1);
  color: rgba(0, 41, 86, 1);
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  min-height: 48px;
}

.encryptedChatHeaderIcon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: white;
  justify-content: center;
  align-items: center;
}

.msgOutboundContainer {
  padding: 1em;
}

.msgInboundContainer {
  padding: 0.5em 1em;
  display: flex;
  justify-content: start;
}

.msgInbound {
  padding: 1em;
  color: white;
  max-width: 75%;
  border-radius: 12px 12px 12px 0;
  background-color: rgba(0, 41, 86, 1);
}

.msgOutboundContainer {
  padding: 0.5em 1em;
  display: flex;
  justify-content: end;
}

.msgOutbound {
  padding: 1em;
  color: white;
  max-width: 75%;
  border-radius: 12px 12px 0 12px;
  background-color: rgba(122, 135, 150, 1);
}

.encryptedChatFooter {
  padding: 0.5em 1em;
}
