.sidebarItem {
  padding: 1em 2em;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.sidebarItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.cardTitle {
  font-family: Arial;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.idContainer {
  text-align: left;
}

.subTitle {
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.notes {
  padding: 0.5em 1em;
  border-radius: 1em;
  background-color: #d9ebff;
  color: #0069ff;
  width: 75%;
  font-family: Arial;
  text-align: center;
  font-weight: normal;
  font-size: small;
}

.qrTitle {
  padding: 0px;
  font-family: Arial;
  text-align: left;
  font-weight: bold;
  font-size: large;
}

.qrNotes {
  padding: 0px;
  font-family: Arial;
  text-align: left;
  font-weight: normal;
  font-size: small;
}

.select {
  background-color: rgba(244, 244, 246, 1) !important;
  color: black !important;
  min-width: 300px;
  text-align: left;
  border-radius: 8px;
}

.spacer {
  flex-grow: 1;
}

.secondaryButton {
  border-color: #d9ebff !important;
  background-color: #d9ebff !important;
  color: #0069ff !important;
}

.clickable {
  cursor: pointer;
}

.modalTitle {
  padding: 0.75em 1.25em;
  background-color: #d9ebff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
}

.modalBody {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.previewContainer {
  border-radius: 1em;
  background-color: #efefef;
  height: 360px;
  text-align: center;
}

.controlContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.content-container {
  height: 360px;
}
